import React, {useEffect, useState} from "react";
import {MapContainer, Marker, Popup, useMap} from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import * as Constants from "../../Constants";
import {apiDevicesForDashboardMap, apiGetAllListData, GEOFENCE} from "../../crud";
import {defaultList} from "../../Common/generic/table/InitialStates";
import {LatLngBounds} from "leaflet";
import {RELOAD_INTERVAL} from "../../Constants";
import { Circle } from 'react-leaflet';

// https://www.npmjs.com/package/react-leaflet-google-layer
// https://react-leaflet.js.org/

let globalReloadRemainingTime = RELOAD_INTERVAL;

export function AllDevicesMap({history, params}) {
    const [reload, setReload] = useState(0);
    const [data, setData] = useState(defaultList);
    const [pos, setPos] = useState([]);
    const [map, setMap] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [timerForReload, setTimerForReload] = useState(RELOAD_INTERVAL);
    const [geoFenceData, setGeoFenceData] = useState([]);

    const [geoFenceCenters, setGeoFenceCenters] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        const tableFilterData = {page: 0,sizePerPage:100,currentIndex:0};
        apiGetAllListData(GEOFENCE, tableFilterData).then(({data}) => {
            data.entities.map((entity) => {
                const lat = Number(entity.location['x']);
                const lng = Number(entity.location['y']);
                if (lat !== 0 && lat !== 0) {
                    geoFenceCenters.push([lat,lng]);
                }
            });
            setGeoFenceCenters(geoFenceCenters);
            setGeoFenceData(data.entities);
            setIsLoading(false);
        })
           .catch(() => {
            setIsLoading(false);
            });
    },[]);
    
    function reloadData() {
        setReload(reload => (reload + 1));
        setTimerForReload(RELOAD_INTERVAL);
    }

    const Recenter = ({lat, lng}) => {
        const map = useMap();
        useEffect(() => {
            
            const allPos = [...pos, ...geoFenceCenters];
            const bounds = new LatLngBounds(allPos);
            
            map.setView([lat, lng]);
            map.fitBounds(bounds);
        }, [lat, lng]);
        return null;
    };

    React.useEffect(() => {
        const timer = window.setInterval(() => {
            resetTimerForReload();
        }, 1000);

        return () => window.clearInterval(timer);
    }, []);

    function resetTimerForReload() {
        globalReloadRemainingTime--;

        if (globalReloadRemainingTime <= 0) {
            globalReloadRemainingTime = RELOAD_INTERVAL;
            reloadData();
        } else {
            setTimerForReload(globalReloadRemainingTime => (globalReloadRemainingTime - 1));
        }
    }

    useEffect(() => {
        apiDevicesForDashboardMap(params).then(({data}) => {
            for (const datum of data.entities) {
                if (Number(datum.lat) !== 0 && Number(datum.lng) !== 0) {
                    pos.push([Number(datum.lat), Number(datum.lng)]);
                }
            }

            setPos(pos);
            setData(data);
        })
            .catch(() => {
            });
    }, [reload]);

    return (<>
        <div className="row">
            <p className="col-lg-12">
                {isLoading && <button
                    type="button"
                    className="btn btn-sm btn-light-primary font-weight-bold ml-2 spinner spinner-right spinner-darker-primary float-right">
                    <span className="flaticon2-refresh"> &nbsp;&nbsp;Reloading Map...</span>
                </button>}

                {!isLoading && <button
                    type="button"
                    onClick={reloadData}
                    className="btn btn-sm btn-light-primary font-weight-bold ml-2 float-right">
                        <span
                            className="flaticon2-refresh"> &nbsp;&nbsp;Reload Map<small> ({timerForReload} Secs)</small></span>
                </button>}
            </p>
        </div>

        <Card>
            {pos.length >= 1 && <CardBody>
                <div style={{height: '70vh', width: '100%', margin: 0, padding: 0}}>
                    <MapContainer
                        style={{height: '100%', width: '100%', zIndex: 0}}
                        zoom={8}
                        whenReady={setMap}
                        center={[0, 0]}>

                        {timerForReload === (RELOAD_INTERVAL - 5) && <Recenter lat={12} lng={77}/>}
                        
                        {geoFenceData && geoFenceData.length > 0 && geoFenceData.map(
                            (geoFence, index) => {
                                if (geoFence.range > 0) {
                                    return (
                                        <Circle
                                            center={[geoFence.location['x'], geoFence.location['y']]}
                                            radius={geoFence.range}
                                            key={index}
                                        />
                                    );
                                }
                            }
                        )}

                        {data && data.entities.map(function (marker, index) {
                            return(
                                <Marker position={[marker.lat, marker.lng]} key={index}>
                                <Popup>
                                    <b>IMEI: </b>{marker.imei}<br/>
                                    <b>VEHICLE NUMBER: </b>{marker.vehicleNumber}<br/>
                                    <b>ODOMETER: </b>{marker.odometer}<br/>
                                    <b>SOC: </b>{marker.soc}<br/>
                                    <b>SPEED: </b>{marker.speed}<br/>
                                    <b>VOLTAGE: </b>{marker.voltage}<br/>
                                    <b>CURRENT: </b>{marker.current}<br/>
                                    <b>STATUS: </b>{marker.status}<br/>
                                    <b>Last GPS Data: </b>{marker.locationLogDate}<br/>
                                    <b>Last Battery Data: </b>{marker.batteryLogDate}<br/>
                                </Popup>
                            </Marker>
                            );
                        })}

                        <ReactLeafletGoogleLayer apiKey={Constants.GOOGLE_MAPS_KEY}/>
                    </MapContainer>
                </div>
            </CardBody>}
        </Card>
    </>);
}
